<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 4 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.99996 8.83337C2.4602 8.83337 2.83329 8.46028 2.83329 8.00004C2.83329 7.5398 2.4602 7.16671 1.99996 7.16671C1.53972 7.16671 1.16663 7.5398 1.16663 8.00004C1.16663 8.46028 1.53972 8.83337 1.99996 8.83337Z"
      stroke="currentcolor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.99996 3.00004C2.4602 3.00004 2.83329 2.62694 2.83329 2.16671C2.83329 1.70647 2.4602 1.33337 1.99996 1.33337C1.53972 1.33337 1.16663 1.70647 1.16663 2.16671C1.16663 2.62694 1.53972 3.00004 1.99996 3.00004Z"
      stroke="currentcolor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.99996 14.6667C2.4602 14.6667 2.83329 14.2936 2.83329 13.8334C2.83329 13.3731 2.4602 13 1.99996 13C1.53972 13 1.16663 13.3731 1.16663 13.8334C1.16663 14.2936 1.53972 14.6667 1.99996 14.6667Z"
      stroke="currentcolor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
